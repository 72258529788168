<template>
  <tr class="product-item" v-if="productItem && renderComponent">
    <td class="input-padding td-stt">
      <span v-if="productItem.billItemType === BILL_ITEM_TYPE.PRODUCT">{{
        productItem.stt
      }}</span>
      <span v-else>
        <b-form-checkbox
          v-model="productItem.checked"
          size="lg"
          @change="checkvalidation"
          :disabled="
            !editable || (productItem.type === 2 && productItem.imeiCode !== '')
          "
        ></b-form-checkbox>
      </span>
    </td>
    <td class="input-padding td-product">
      <div class="d-flex d-inline">
        <i
          v-if="productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
          style="color: #2e7d32; font-size: 1rem"
          class="fas fa-gift mr-1"
          title="Quà tặng"
        ></i>
        <i
          v-if="productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION"
          style="color: #37474f; font-size: 1rem"
          class="fas fa-tags"
          title="Sản phẩm bán kèm"
        ></i>
        <span
          style="font-weight: 500; font-size: 0.85rem"
          class="m-0"
          v-if="productItem.code"
        >
          ({{ productItem.code }})
        </span>
      </div>
      <div class="mt-1 mb-2">
        <p
          style="font-weight: 600; cursor: pointer"
          class="m-0 productName"
          @click="viewInventory"
        >
          {{ productItem.name }}
          <span :class="['activation-status', { 'not-activated': !isActivatedVieon }]" v-if="shouldShowVieonStatus">{{ activationStatusVieon }}</span>
        </p>
        <MerchantLabel :merchantId="productItem.merchantId" />
        <div v-show="productItem.type === 2">
          <b-form-input
            v-model="$v.productItem.imeiCode.$model"
            placeholder="Nhập IMEI code"
            size="sm"
            class="input-text-size"
            @change="isAvailableQuantity(productItem.imeiCode)"
            :disabled="!editIMEI"
          ></b-form-input>
          <b-form-invalid-feedback :state="$v.productItem.imeiCode.minLength"
            >Vui lòng nhập imei code ít nhất 5 kí tự
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="validate"
            >{{ messageError }}
          </b-form-invalid-feedback>
          <div v-if="!productItem.imeiCode">
            <div
              v-if="
                (productItem.quantityInStock > 0 && editIMEI) ||
                  (productItem.quantityInStock > 0 &&
                    productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS)
              "
              class="mt-2 billCode"
              style="cursor: pointer"
              @click="linkToListImei"
            >
              <span style="color: blue">Danh sách IMEI</span>
            </div>
          </div>
        </div>

        <div
          class="table-responsive"
          v-show="
            productItem.comboItems !== undefined &&
              productItem.comboItems.length > 0
          "
        >
          <table>
            <tr
              v-for="(items, i) in productItem.comboItems"
              :key="i"
              style="border-top: 1px solid gray"
            >
              <td style="border: none">
                <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
              </td>
              <td style="border: none; width: -webkit-fill-available">
                {{ items.productName }}
                <i style="font-size: unset; color: unset"
                  >({{ formatPrice(items.sellingPrice) }})</i
                >
              </td>
              <td style="border: none">{{ items.quantity }}</td>
            </tr>
          </table>
        </div>
        <div v-if="!productItem.flexibleComboId">
          <p>Tồn CTB: {{ productItem.quantityInStock }}</p>
        </div>
        <div
          class="text-muted fs-7"
          v-if="
            productItem.externalImeiNo &&
              productItem.allowViewFullExternalImeiNo === false
          "
        >
          <i class="fa fa-link fa-sm fa-solid"></i>
          <span class="text-external-imei-no font-weight-bolder">{{
            productItem.externalImeiNo
          }}</span>
        </div>
        <div
          v-if="
            [undefined, true].includes(
              productItem.allowViewFullExternalImeiNo,
            ) &&
              (productItem.belongToWarrantyImei || productItem.externalImeiNo)
          "
        >
          <b-form-input
            size="sm"
            style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
            v-model="productItem.externalImeiNo"
            placeholder="IMEI đính kèm (bảo hành)"
            type="text"
            :min="1"
            class="w-100 h-90 text-input-size"
            @change="checkvalidation"
            :disabled="!editable"
          ></b-form-input>
        </div>

        <div v-if="productItem.billItemType === 1">
          <p v-if="productItem.note.trim() !== ''" class="text-note">
            Ghi chú: {{ productItem.note }}
          </p>
        </div>
      </div>
    </td>
    <td class="input-padding td-quantity">
      <div
        v-if="productItem.customerDiscountAmount"
        style="visibility: hidden;"
      >
        hidden
      </div>
      <div
        v-if="
          productItem.type === PRODUCT_TYPE.PRODUCT ||
            productItem.type === PRODUCT_TYPE.PRODUCT_COMBO
        "
      >
        <b-form-input
          v-if="
            productItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
              !productItem.flexibleComboId &&
              !productItem.belongToWarrantyImei
          "
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
          v-model="productItem.quantity"
          type="text"
          placeholder="SL"
          class="w-100 h-90 text-right text-input-size"
          @change="checkvalidation"
          :disabled="!editable"
          v-mask="mask"
        ></b-form-input>
        <span v-else>{{ productItem.quantity }}</span>
      </div>

      <span v-else>{{ productItem.quantity }}</span>
    </td>
    <td class="input-padding td-product-price">
      <div
        v-if="productItem.customerDiscountAmount"
        style="visibility: hidden;"
      >
        hidden
      </div>
      <span
        v-if="
          checkPermission('BILL_EDIT_PRODUCT_PRICE') &&
            productItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
            !productItem.flexibleComboId
        "
      >
        <span class="d-block text-danger" v-if="getInstallmentInterestZero">
          Giá niêm yết
        </span>
        <b-form-input
          size="sm"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
          v-model="productItem.price"
          placeholder="Đơn giá"
          type="text"
          :min="1"
          class="w-100 h-90 text-input-size"
          @change="checkvalidation"
          :disabled="!editable"
        ></b-form-input>
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </span>
      <span v-else>
        <span class="d-block text-danger" v-if="getInstallmentInterestZero">
          Giá niêm yết
        </span>
        {{ formatPrice(productItem.price) }}
      </span>
    </td>
    <td class="input-padding td-selling-price">
      <div
        v-if="productItem.customerDiscountAmount"
        style="visibility: hidden;"
      >
        hidden
      </div>
      <span v-if="productItem.billItemType === 1">{{
        productItem.quantity === 0 ? 0 : formatPrice(totalPriceOfProduct)
      }}</span>
      <div v-else class="mb-2">
        <div
          v-if="
            productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS ||
              productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION
          "
        >
          <span class="text-primary">{{
            productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS
              ? 'Giá thu lại'
              : 'Giá chiết khấu'
          }}</span>
          <br />
          <!-- <b-form-input
            size="sm"
            style="
              height: calc(1.35rem + 1.1rem + 2px);
              border-color: #007bff;
              text-align: right;
            "
            v-model="productItem.returnPrice"
            :placeholder="
              productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS
                ? 'Giá thu lại'
                : 'Giá chiết khấu'
            "
            :min="1"
            class="w-100 h-90 text-input-size"
            @change="checkvalidation"
            v-mask="mask"
            :disabled="!editable"
          ></b-form-input> -->
          {{ formatPrice(totalPriceOfProductPromotions) }}
          <!-- {{ productItem.returnPrice }} -->
        </div>
      </div>
    </td>
    <td class="input-padding td-discount">
      <span
        v-if="
          productItem.customerDiscountType === 'percent' &&
            productItem.customerDiscountAmount &&
            productItem.billItemType !== ITEM_TYPE.GIFT
        "
        class="font-italic"
        style="color: rgb(255, 168, 0);"
      >
        Thành viên: {{ productItem.customerDiscountAmount }}%</span
      >
      <span
        v-if="
          productItem.customerDiscountType === 'amount' &&
            productItem.customerDiscountAmount &&
            productItem.billItemType !== ITEM_TYPE.GIFT
        "
        class="font-italic"
        style="color: rgb(255, 168, 0);"
      >
        Thành viên:
        {{ formatPrice(productItem.customerDiscountAmount) }} VND</span
      >
      <div
        v-if="
          productItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
            !productItem.flexibleComboId &&
            // !productItem.voucherCode &&
            (!productItem.vouchers || !productItem.vouchers.length)
        "
      >
        <b-input-group>
          <template #prepend>
            <b-dropdown
              :text="
                productItem.discountType == DISCOUNT_AMOUNT_TYPE.MONEY
                  ? '$'
                  : productItem.discountType == DISCOUNT_AMOUNT_TYPE.PERCENT
                  ? '%'
                  : ''
              "
              size="sm"
              @change="checkvalidation"
              variant="outline-primary"
            >
              <b-dropdown-item @click="onClickDiscountType(1)"
                >{{ '$' }}
              </b-dropdown-item>
              <b-dropdown-item @click="onClickDiscountType(2)"
                >{{ '%' }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-input
            size="sm"
            style="
              height: calc(1.35rem + 1.1rem + 2px);
              border: 1px solid #007bff;
              text-align: right;
            "
            v-model="productItem.discount"
            placeholder="Chiết khấu"
            type="text"
            :min="1"
            @change="checkvalidation"
            v-mask="mask"
            aria-describedby="input-discount-live-feedback"
            class="text-input-size"
          ></b-form-input>
          <b-form-invalid-feedback :state="validateDiscount"
            >{{ messageErrorDiscount }}
          </b-form-invalid-feedback>
        </b-input-group>
        <span class="font-italic">{{ discountProgramName }}</span>
      </div>
      <div v-else>
        <span>{{ formatPrice(unMaskPrice(productItem.discount)) }}</span
        ><br />
        <span class="font-italic" v-if="discountProgramName"
          >CT: {{ discountProgramName }}</span
        >

        <div
          class="d-flex justify-content-end mb-1"
          v-for="(item, index) in productItem.vouchers"
          :key="index"
        >
          <VoucherTag
            :voucherCode="item.voucherCode"
            v-on:removeTag="onRemoveVoucher(index)"
          />
        </div>
        <!-- <div class="d-flex justify-content-end" v-if="productItem.voucherCode">
          <VoucherTag
            :voucherCode="productItem.voucherCode"
            v-on:removeTag="onRemoveVoucher"
          />
        </div> -->
      </div>
    </td>
    <td class="td-vat">
      <div
        v-if="productItem.customerDiscountAmount"
        style="visibility: hidden;"
      >
        .
      </div>
      <span v-if="productItem.productNameVat">
        <b-form-checkbox
          v-model="productItem.isGetVat"
          size="lg"
          @change="checkvalidation"
          :disabled="!editable"
        ></b-form-checkbox>
      </span>
    </td>
    <td class="td-action">
      <template>
        <div class="d-flex justify-content-center">
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
            v-if="productItem.flexibleComboId"
            :disabled="!editable"
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item @click="showDeleteAlert">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
            v-else-if="
              productItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
                !editable &&
                !editIMEI &&
                productItem.imeiCode &&
                checkPermission('ACTIVE_IMEI_BILL')
            "
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              @click="onActiveImei(productItem.imeiCode)"
              v-if="!editIMEI && productItem.imeiCode"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fa fa-check"></i>
                &nbsp; Kích hoạt bảo hành
              </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
            v-else-if="productItem.billItemType === BILL_ITEM_TYPE.PRODUCT"
            :disabled="!editable"
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item @click="addProductBonus">
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fas fa-gift"></i>
                &nbsp; Thêm quà tặng
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="addProductPromotion"
              v-if="!getInstallmentInterestZero"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fas fa-tags"></i>
                &nbsp; Thêm SP bán kèm
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="addProductWarranty">
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fas fa-shield-alt"></i>
                &nbsp; Thêm SP bảo hành
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="addVoucher">
              <!-- <b-dropdown-item
              @click="addVoucher"
              v-if="!getInstallmentInterestZero && !productItem.voucherCode"
            > -->
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fas fa-tags"></i>
                &nbsp; Thêm voucher
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="addNote">
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="flaticon2-notepad"></i>
                &nbsp; Thêm ghi chú
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="onActiveImei(productItem.imeiCode)"
              v-if="!editIMEI && productItem.imeiCode"
              v-show="checkPermission('ACTIVE_IMEI_BILL')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="fa fa-check"></i>
                &nbsp; Kích hoạt bảo hành
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showDeleteAlert">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
            v-else
            :disabled="!editable"
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              @click="changeProductBonus"
              v-if="productItem.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i class="fas fa-undo"></i>
                &nbsp; Đổi quà tặng
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showDeleteAlert">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="mt-1"
          v-b-modal="`modal-${billItemIndex}`"
        >
          <i
            style="font-size: 1rem; padding-right: 0px"
            class="flaticon-safe-shield-protection"
          ></i>
        </b-button>
      </template>
    </td>
    <b-modal
      :id="`modal-${billItemIndex}`"
      title="Thông tin bảo hành nhà sản xuất"
      hide-footer
    >
      <p class="my-4">
        <strong>Tên sản phẩm:</strong>
        {{ getDefaultInformation(productItem.name) }}
      </p>
      <p class="my-4">
        <strong>Địa chỉ BH:</strong>
        {{ getDefaultInformation(productItem.warrantyAddress) }}
      </p>
      <p class="my-4">
        <strong>Số điện thoại BH:</strong>
        {{ getDefaultInformation(productItem.warrantyPhone) }}
      </p>
      <p class="my-4">
        <strong>Số tháng BH:</strong>
        {{ getDefaultInformation(productItem.warrantyMonthNo) }}
      </p>
      <b-form-group
        label="Nội dung BH:"
        :label-for="`textarea-plaintext-${billItemIndex}`"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-form-textarea
          :id="`textarea-plaintext-${billItemIndex}`"
          plaintext
          size="lg"
          :value="productItem.warrantyDescription || noInformationMessage"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
  </tr>
</template>

<style scoped>
tr td {
  padding: 0.75rem !important;
}

.td-stt {
  width: 4%;
  text-align: center;
}

.td-product {
  width: 30%;
}

.td-quantity {
  width: 10%;
  text-align: right;
}

.td-product-price {
  width: 17%;
  text-align: right;
}

.td-selling-price {
  width: 15%;
  text-align: right;
}

.td-discount {
  width: 22%;
  text-align: right;
}

.td-vat {
  width: 3%;
}

.td-action {
  width: 5%;
}

.product-item {
  height: 40px;
}

*:focus {
  outline: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.text-note {
  font-size: 12px;
  font-style: italic;
}

.billCode:hover {
  text-decoration: underline;
  color: blue;
}

.productName:hover {
  text-decoration: underline;
}
.activation-status { 
  font-size: 0.95rem;
  color: #28a745; /* màu xanh lá cho trạng thái "đã kích hoạt" */
  font-weight: 600;
  margin-left: 2px;
}
.activation-status.not-activated {
    color: #dc3545; /* màu đỏ cho trạng thái "chưa kích hoạt" */
}

.text-input-size {
  font-size: 12px;
}

.input-padding {
  padding: 0 7px 0 7px;
}

.text-external-imei-no {
  font-size: 12px;
  font-style: italic;
  color: rgb(46, 125, 50);
}
</style>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { minLength } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import { currencyMask, formatPrice, unMaskPrice } from '@/utils/common';
import { cmdUrl } from '@/utils/apiUrl';
import {
  AMOUNT_TYPE,
  BILL_ITEM_TYPE,
  PRODUCT_TYPE,
  ITEM_TYPE,
} from '@/utils/enum';
import localData from '@/utils/saveDataToLocal';
import MerchantLabel from './MerchantLabel';
import { mapGetters } from 'vuex';
import VoucherTag from '@/view/components/voucher/VoucherTag';
import { makeToastFaile } from '@/utils/common';

export default {
  props: ['productItem', 'editable', 'editIMEI', 'productIds', 'billItemIndex'],
  mixins: [validationMixin],
  components: {
    MerchantLabel,
    VoucherTag,
  },

  watch: {
    productItem: {
      handler(value) {
        console.log(value);
      },
      deep: true,
    },

    // nếu discout vượt giá trị sản phẩm thì giá trị discount sẽ = giá sản phẩm
    // 'productItem.discount': {
    //   handler(newVal) {
    //     if (newVal > this.productItem.price) {
    //       this.productItem.discount = this.productItem.price;
    //     }
    //   },
    //   deep: true,
    // },
    //   productIds: {
    //     handler: function(newVal, val) {
    //       if (newVal !== val) {
    //         this.getProductDiscount();
    //       }
    //     },
    //   },
  },
  data() {
    return {
      discountProgramName: this.productItem.discountProgram
        ? this.productItem.discountProgram.name
        : '',
      error: false,
      validate: true,
      mask: currencyMask,
      messageError: '',
      renderComponent: true,
      validateDiscount: true,
      messageErrorDiscount: '',
      DISCOUNT_AMOUNT_TYPE: AMOUNT_TYPE,
      BILL_ITEM_TYPE: BILL_ITEM_TYPE,
      PRODUCT_TYPE: PRODUCT_TYPE,
      ITEM_TYPE: ITEM_TYPE,
      count: 0,
      noInformationMessage: 'Không có thông tin',
      isActivatedVieon: this.productItem.statusVieon,
    };
  },
  computed: {
    ...mapGetters(['getInstallmentInterestZero', 'getInstallmentProgram']),
    totalPriceOfProduct() {
      const discountType = this.productItem.discountType;

      if (this.productItem.quantity <= 0) {
        return 0;
      }
      let totalAmount = 0;

      // total giá chiết khấu

      totalAmount =
        unMaskPrice(this.productItem.quantity) *
        unMaskPrice(this.productItem.price);

      if (discountType == AMOUNT_TYPE.PERCENT) {
        let amount = totalAmount * unMaskPrice(this.productItem.discount);
        amount = (amount / 100).toFixed(0);
        totalAmount = totalAmount - amount > 0 ? totalAmount - amount : 0;
      }
      if (discountType == AMOUNT_TYPE.MONEY) {
        totalAmount =
          totalAmount - unMaskPrice(this.productItem.discount) > 0
            ? totalAmount - unMaskPrice(this.productItem.discount)
            : 0;
      }
      if (this.productItem.customerDiscountType === 'percent') {
        let amount =
          totalAmount * (this.productItem.customerDiscountAmount / 100);

        if (
          this.productItem.customerDiscountMaxAmount &&
          amount > this.productItem.customerDiscountMaxAmount
        ) {
          amount = this.productItem.customerDiscountMaxAmount;
        }

        // if(amount > this.productItem.customer)
        totalAmount = totalAmount - amount.toFixed(0);

        totalAmount = Number(totalAmount) < 0 ? 0 : totalAmount;
      }
      if (this.productItem.customerDiscountType === 'amount') {
        totalAmount =
          totalAmount - this.productItem.customerDiscountAmount < 0
            ? 0
            : totalAmount - this.productItem.customerDiscountAmount;
      }

      return totalAmount;
    },
    totalPriceOfProductPromotions() {
      const discountType = this.productItem.discountType;

      if (this.productItem.quantity <= 0) {
        return 0;
      }
      let totalAmount = 0;
      // total giá chiết khấu
      totalAmount =
        unMaskPrice(this.productItem.quantity) *
        unMaskPrice(this.productItem.returnPrice);

      if (discountType == AMOUNT_TYPE.PERCENT) {
        let amount = totalAmount * unMaskPrice(this.productItem.discount);
        amount = (amount / 100).toFixed(0);
        totalAmount = totalAmount - amount > 0 ? totalAmount - amount : 0;
      }
      if (discountType == AMOUNT_TYPE.MONEY) {
        totalAmount =
          totalAmount - unMaskPrice(this.productItem.discount) > 0
            ? totalAmount - unMaskPrice(this.productItem.discount)
            : 0;
      }

      if (this.productItem.customerDiscountType === 'percent') {
        let amount =
          totalAmount * (this.productItem.customerDiscountAmount / 100);
        if (
          this.productItem.customerDiscountMaxAmount &&
          amount > this.productItem.customerDiscountMaxAmount
        ) {
          amount = this.productItem.customerDiscountMaxAmount;
        }
        totalAmount = totalAmount - amount.toFixed(0);
        totalAmount = Number(totalAmount) < 0 ? 0 : totalAmount;
      }
      if (this.productItem.customerDiscountType === 'amount') {
        totalAmount =
          totalAmount - this.productItem.customerDiscountAmount < 0
            ? 0
            : totalAmount - this.productItem.customerDiscountAmount;
      }
      return totalAmount;
    },
    activationStatusVieon() { 
      return this.productItem.statusVieon ? 'đã kích hoạt' : 'chưa kích hoạt';
    },
    shouldShowVieonStatus() {
      const hasBillNumber = !!this.$route.query.id;
      const isVieonProduct = this.productItem.code.startsWith('vieon');

      return hasBillNumber && isVieonProduct;
    },
  },
  created() {
    if (this.productIds.length) {
      this.getProductDiscount();
    }
  }, 
  validations: {
    productItem: {
      imeiCode: {
        minLength: minLength(5),
      },
    },
  },
  methods: {
    unMaskPrice,
    formatPrice,
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    getProductDiscount: async function() {
      if (
        !this.productItem.id ||
        (this.productItem.id && this.productItem.discountProgramId)
      ) {
        let params = {
          productId: this.productItem.productId,
          storeId: this.productItem.storeId,
        };
        const query = {
          params: {
            productIds: this.productIds,
          },
        };
        await ApiService.query(
          cmdUrl.DiscountProgramUrl.byProduct +
            `/${params.productId}` +
            `/${params.storeId}`,
          query,
        ).then((response) => {
          if (response.data.status === 1) {
            const discountProgram = response.data.data;
            if (!discountProgram) {
              return;
            }
            // this.productItem.discountType = discountProgram.discountType || 1;
            // this.productItem.discount = discountProgram.discountAmount || 0;
            this.productItem.discountProgramId = discountProgram.id;
            this.discountProgramName = discountProgram.name;
            if (discountProgram.discountType == AMOUNT_TYPE.PERCENT) {
              let amount =
                this.productItem.price *
                unMaskPrice(discountProgram.discountAmount);
              amount = (amount / 100).toFixed(0);
              this.productItem.discount = amount;
              this.productItem.discountProgramAmount = amount;
              this.discountProgramName = `${this.discountProgramName} - CK:${discountProgram.discountAmount}%`;
            }
            if (discountProgram.discountType == AMOUNT_TYPE.MONEY) {
              this.productItem.discount = discountProgram.discountAmount;
              this.productItem.discountProgramAmount =
                discountProgram.discountAmount;
            }
            this.productItem.discountType = AMOUNT_TYPE.MONEY;
            this.forceRerender();
          }
        });
      }
    },
    isAvailableQuantity: async function(value) {
      this.validate = false;
      let dataResponse = this.productItem;
      if (value === '') return this.validate;

      let params = {
        storeId: this.productItem.storeId,
        imei: this.productItem.imeiCode,
      };
      await ApiService.query('bills/check-valid-imei', {
        params,
      })
        .then((response) => {
          if (response.data.status === 1) {
            if (response.data.data.sellingPrice !== 0) {
              this.productItem.price = response.data.data.sellingPrice;
              dataResponse.price = unMaskPrice(this.productItem.price);
              this.forceRerender();
            }
            this.validate = true;
          } else {
            this.validate = false;
            this.messageError = response.data.message;
          }
          this.$emit('checkValidImei', this.validate);
          this.$emit('updateExternalImeiNo', this.productItem);
          this.$emit('update', dataResponse);
        })
        .catch(() => {
          this.$emit('checkValidImei', this.validate);
          this.$emit('update', dataResponse);
          this.validate = false;
        });

      return false;
    },
    checkvalidation() {
      if (
        this.productItem.discountType == AMOUNT_TYPE.PERCENT &&
        unMaskPrice(this.productItem.discount) > 100
      ) {
        this.validateDiscount = false;
        this.messageErrorDiscount = 'Chiết khấu vượt mức 100%!';
      } else if (
        this.unMaskPrice(this.productItem.discountManual) >
        this.productItem.price
      ) {
        this.validateDiscount = false;
        this.messageErrorDiscount = 'Chiết khấu vượt giá sản phẩm';
      } else {
        this.validateDiscount = true;
        this.messageErrorDiscount = '';
      }
      this.error = false;
      const dataResponse = this.productItem;
      dataResponse.price = this.productItem.price
        ? unMaskPrice(this.productItem.price)
        : 0;
      this.productItem.discountManual = dataResponse.discount;
      // dataResponse.discount = this.productItem.discountManual
      //   ? unMaskPrice(this.productItem.discountManual)
      //   : 0;
      dataResponse.quantity = this.productItem.quantity
        ? unMaskPrice(this.productItem.quantity)
        : 0;
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function() {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi hóa đơn không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.productItem.id);
        }
      });
    },
    addProductBonus: function() {
      this.$emit('addProductBonus', this.productItem.id);
    },
    addProductPromotion: function() {
      this.$emit('addProductPromotion', this.productItem.id);
    },
    changeProductBonus: function() {
      this.$emit('changeProductBonus', this.productItem.id);
    },
    addNote: function() {
      this.$emit('addNote', this.productItem.id);
    },
    linkToListImei: function() {
      this.$emit('linkToListImei', this.productItem.id);
    },
    viewInventory: function() {
      this.$emit('viewInventory', this.productItem.id);
    },
    onClickDiscountType(type) {
      if (type === AMOUNT_TYPE.MONEY) {
        this.productItem.discountType = AMOUNT_TYPE.MONEY;
      } else if (type === AMOUNT_TYPE.PERCENT) {
        this.productItem.discountType = AMOUNT_TYPE.PERCENT;
      }
      this.checkvalidation();
    },
    addProductWarranty: function() {
      this.$emit('addProductWarranty', this.productItem.productId);
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    onActiveImei(imeiCode) {
      this.$emit('activeImei', imeiCode);
    },
    addVoucher: function() {
      this.$emit('addVoucher', this.productItem.id);
    },
    // onRemoveVoucher() {
    //   const dataResponse = this.productItem;
    //   dataResponse.prevVoucherId = this.productItem.voucherId;
    //   dataResponse.voucherId = null;
    //   dataResponse.voucherDetailId = null;
    //   dataResponse.voucherCode = '';
    //   dataResponse.discount = 0;
    //   dataResponse.discountAmount = 0;
    //   this.$emit('update', dataResponse);
    // },
    onRemoveVoucher(index) {
      const dataResponse = this.productItem;
      const voucherDelete = dataResponse.vouchers[index];
      if (!voucherDelete) {
        makeToastFaile('Voucher không tồn tại');
        return;
      }
      let vouchers = this.productItem.vouchers;

      dataResponse.vouchers = vouchers.filter((item, i) => i !== index);
      const totalVoucherAmount = dataResponse.vouchers.reduce(
        (total, voucher) => total + voucher.voucherAmount,
        0,
      );
      const totalDiscountAmount =
        Number(dataResponse.discountManual) +
        Number(dataResponse.discountProgramAmount) +
        Number(totalVoucherAmount);

      dataResponse.prevVoucherId = this.productItem.voucherId;
      dataResponse.discount =
        totalDiscountAmount > dataResponse.price
          ? dataResponse.price
          : totalDiscountAmount;
      dataResponse.discountAmount =
        totalDiscountAmount > dataResponse.price
          ? dataResponse.price
          : totalDiscountAmount;
      // dataResponse.discount -= voucherDelete.voucherAmount;
      // dataResponse.discountAmount -= voucherDelete.voucherAmount;
      dataResponse.oldVoucherCumluativeValue[voucherDelete.voucherId][
        'amount'
      ] = 0;
      this.$emit('update', dataResponse);
    },
    getDefaultInformation(text) {
      if (typeof text !== 'string') {
        if (!isNaN(Number(text))) return text;
        return this.noInformationMessage;
      }
      return text.length > 0 ? text.trim() : this.noInformationMessage;
    },
  },
};
</script>
